/*
 * 업무구분: 상품
 * 화 면 명: MSPFS200M
 * 화면설명: 판매자료조회4820
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */

<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">

      <ur-box-container direction="column" alignV="start">
        <div class="default info">
          <p>펀드상품 조회 및 상품별 판매자료는<br/><b>판매자료조회 웹사이트</b>에서 확인 가능합니다.</p>
          <br/>
          <mo-button @click="fn_popup" class="link ico-link">웹사이트 바로가기</mo-button>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button v-if="lv_isProcess"
            primary size="large"
            @click="fn_ConfirmModal()"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS202M',
  screenId: 'MSPFS202M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_popup()
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS202M')
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '판매자료조회',
        step: 6
      },
      pAlertPopupObj: {},
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess

    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {

  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },

    fn_popup () {
      // var url = "microsoft-edge:https://www.samsunglife.com/individual/products/disclosure/fund/PDO-PRFUT010200M?fundName=";
      // window.open(url, '_blank');
      // 모바일 테스트 환경이 구성 된 후 아래 코드로 수정 예정
      window.fdpbridge.exec('intentBrowserPlugin', {uri: 'https://www.samsunglife.com/individual/products/disclosure/fund/PDO-PRFUT010200M?'}, () => {}, () => {})
    },

    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true
        this.pAlertPopupObj.type = 0
        this.pAlertPopupObj.contentTitle = ''
        this.pAlertPopupObj.btnType = 0

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
        if ( !FSCommUtil.gfn_isNull(type) && type !== 0 ) {
          this.pAlertPopupObj.type = type
        }
        if ( pPopupObj.contentTitle ) {
          this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
        }
        if ( pPopupObj.btnType ) {
          this.pAlertPopupObj.btnType = pPopupObj.btnType
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_ConfirmModal
       * 설명       : 고객등록 완료 팝업
    ******************************************************************************/
    fn_ConfirmModal () {
      console.log('fn_ConfirmModal.....', this.$route.params )

      const lv_vm = this

      let procTyp = lv_vm.getStore('fsStore').getters.getBasInfo.data.procTyp
      let btnType = procTyp === 'accnNew' ? 1 : 0

      let params = {}
      if (!FSCommUtil.gfn_isNull(this.$route.params.ed_rname_no)) {
        params.ed_rname_no = lv_vm.$route.params.ed_rname_no[0] + lv_vm.$route.params.ed_rname_no[1]
      }

      if ( procTyp === 'accnNew' ) {
        params.ed_group_no = lv_vm.$route.params.bfcer_cust_no + '001'

        let t_popupObj = {
          closeFunc: function () { // 계좌신규 이동
            lv_vm.$router.push({
              name: 'MSPFS307M',
              params: params
            })
          },
          confirm: true,
          confirmFunc: function () { // 연금계좌신규개설 이동
            lv_vm.$router.push({
              name: 'MSPFS303M',
              params: params
            })
          },
          btnType: btnType,
          content: '고객등록이 완료되었습니다. (' + lv_vm.$route.params.bfcer_cust_no + ') 이동할 화면을 선택해 주세요.'
        }
        this.fn_AlertPopup(2, t_popupObj)
      } else {
        params.ed_cust_no = lv_vm.$route.params.bfcer_cust_no
        lv_vm.$router.push({ // 그룹신규 이동
          name: 'MSPFS300M',
          params: params
        })
      }
    }

  }
}
</script>
<style scoped>
</style>
